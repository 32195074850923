import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MyImage = makeShortcode("MyImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Let's pretend we're building a module that displays music albums in an artist's website, and we want to document how selecting an album works.`}</p>
    <h3 {...{
      "id": "create-a-first-note",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#create-a-first-note",
        "aria-label": "create a first note permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Create a first note`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`Command-N`}</inlineCode>{` to create a note.`}</p>
    <MyImage src="creating-notes-1.png" alt="Creating Notes 1" preset="no-border" mdxType="MyImage" />
    <p>{`The note can be associated to any of your repositories. To be more precise, it is associated to `}<strong parentName="p">{`the commit`}</strong>{` the head of the working copy is pointing to.`}</p>
    <p>{`What it means is: the notes which are shown to you depend on what branch you're currently pointing to, and if no repository is associated to the note, it will always be visible.`}</p>
    <MyImage src="notes-commits.svg" alt="Instructions" preset="no-border" mdxType="MyImage">
  In this example, "Note 2" will not be shown while you're at HEAD.
    </MyImage>
    <p>{`But don't worry, you can change your mind later.`}</p>
    <p>{`Let's leave the check box unchecked for now and create a Note called "Features".`}</p>
    <p>{`Fill in some text in the first paragraph. By default, the first paragraph of the note is supposed to describe what the note is about.`}</p>
    <p>{`This is a note setting that you can toggle in the inspector.`}</p>
    <MyImage src="creating-notes-2.png" alt="Creating Notes 1" style={{
      maxWidth: '30%',
      margin: 'auto'
    }} mdxType="MyImage" />
    <h3 {...{
      "id": "create-a-child-note",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#create-a-child-note",
        "aria-label": "create a child note permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Create a child note`}</h3>
    <p>{`Hit `}<inlineCode parentName="p">{`Command-N`}</inlineCode>{` again.`}</p>
    <p>{`By default, the note will be created as a child of the current note.`}</p>
    <p>{`Let's call it "Album selection" and, this time, associate the repository by clicking the checkbox.`}</p>
    <MyImage src="creating-notes-3.png" alt="Creating Notes 3" preset="no-border" mdxType="MyImage" />
    <p>{`Briefly describe the note, then we'll add a screenshot.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      